import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const PrivacyPolicy = () => (
  <Layout headerRight="restaurants">
    <SEO
      pageTitle="Informativa sul trattamento dei dati personali - Mangiami"
      pageDescription="Questa informativa descrive la politica in materia di trattamento dei dati personali in relazione all'uso della nostra piattaforma online."
      pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
      pageUrl="/privacy-policy"
    />
    <div
      style={{
        paddingTop: 40,
        paddingBottom: 20,
        maxWidth: 900,
        margin: '0 auto',
      }}
    >
      <p>
        <strong>PRIVACY POLICY E COOKIE POLICY</strong>
      </p>
      <p>
        <strong>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>INTRODUZIONE</strong>
      </p>
      <p>
        Il presente documento descrive la politica in materia di trattamento dei dati personali in
        relazione all&rsquo;uso della piattaforma online &ldquo;<em>mangiami</em>&rdquo; (la
        &ldquo;Piattaforma&rdquo;), composta dal sito&nbsp;
        <a href="https://mangiami.ch/">mangiami.ch</a> (il &ldquo;Sito&rdquo;) e delle risorse ad
        esso collegate, incluse le pagine e i profili sui social media (in particolare: Facebook e
        Instagram), nonch&eacute; l&rsquo;App per dispositivi mobili &ldquo;<em>mangiami</em>&rdquo;
        (l&rsquo;&ldquo;App&rdquo;), disponibile gratuitamente e senza registrazione presso gli
        store online.
      </p>
      <p>
        Il presente documento informa l&rsquo;utente, ai sensi e per gli effetti degli articoli 4
        cpv. 5 della Legge federale sulla protezione dei dati (LPD) e, ove applicabile, degli art.
        13 e 14 del Regolamento europeo (UE) 679/2016 (GDPR), che i dati personali forniti oppure
        altrimenti acquisiti nell&rsquo;ambito dell&rsquo;attivit&agrave; della Piattaforma saranno
        oggetto di trattamento, nel rispetto dei principi enunciati dalle normative sopra indicate.
      </p>
      <p>
        Si precisa che il GDPR, conformemente all&rsquo;art. 3, si applica unicamente in caso di
        trattamenti di dati personali legati a:
      </p>
      <li>offerta di beni o servizi a persone fisiche nell&#39;UE;</li>
      <li>monitoraggio del comportamento di persone fisiche nell&rsquo;UE.</li>
      <p>
        I <u>paragrafi A e B</u> specificano la titolarit&agrave; della Piattaforma, le coordinate
        per le comunicazioni e descrivono il meccanismo di accettazione e revisione del presente
        documento. I <u>paragrafi C e D</u> descrivono la politica del titolare relativa al
        trattamento dei dati personali degli utenti. Infine, il <u>paragrafo E</u> regola il diritto
        materiale applicabile alla relazione giuridica tra le parti e stabilisce il foro competente
        in caso di vertenza collegata a tale relazione.
      </p>
      <p>
        Le condizioni che disciplinano l&rsquo;uso della Piattaforma sono descritte in un documento
        separato, accessibile a questo&nbsp;
        <a href="https://mangiami.ch/terms-and-conditions/">link</a>. Lo stesso s&rsquo;intende qui
        integralmente riprodotto e integrato.
      </p>
      <p>&nbsp;</p>
      <li>
        <strong>TITOLARE DEL SITO E COMUNICAZIONI</strong>
      </li>
      <p>
        Il Sito &egrave; di propriet&agrave; di Hextech SA, Sorengo (CH) (&ldquo;Hextech&rdquo; o
        &ldquo;Titolare&rdquo;).
      </p>
      <p>
        Tutte le comunicazioni dovranno avvenire per iscritto e s&rsquo;intenderanno validamente ed
        efficacemente eseguite alla ricezione delle stesse, se effettuate per posta ordinaria,
        rispettivamente al momento dell&rsquo;invio della conferma di lettura, se effettuate per
        posta elettronica.
      </p>
      <p>
        <u>Contatti</u>:
      </p>
      <ul>
        <li>Hextech SA, Via Paradiso 24, 6924 Sorengo (CH)</li>
        <li>Tel. 076 534 55 47 (dalla Svizzera)</li>
        <li>
          E-mail:&nbsp;<a href="mailto:support@mangiami.ch">support@mangiami.ch</a>
        </li>
      </ul>
      <p>&nbsp;</p>
      <li>
        <strong>ACCETTAZIONE E MODIFICA DELLA PRESENTE POLITICA</strong>
      </li>
      <p>
        Mediante l&rsquo;uso della Piattaforma, l&rsquo;utente accetta i termini e le condizioni,
        nonch&eacute; i trattamenti di dati personali descritti dalla presente politica, nella
        versione in vigore al momento dell&rsquo;uso. La versione vigente pu&ograve; essere
        visualizzata cliccando sull&rsquo;apposito link posto in calce al Sito. &Egrave;
        responsabilit&agrave; dell&rsquo;utente verificare con cura lo stato dei termini e delle
        condizioni, nonch&eacute; dell&rsquo;informativa prima di utilizzare la Piattaforma, essendo
        riservato il diritto del Titolare di aggiornare il presente documento in qualsiasi momento e
        a propria discrezione, segnatamente in funzione dell&rsquo;evoluzione del diritto
        applicabile, delle funzionalit&agrave;, nonch&eacute; dei servizi e dei prodotti messi a
        disposizione dell&rsquo;utente.
      </p>
      <p>
        Ove richiesto dalla legge, il Titolare richieder&agrave; un consenso espresso da parte
        dell&rsquo;utente tramite i canali elettronici (online oppure tramite e-mail).
      </p>
      <p>&nbsp;</p>
      <li>
        <strong>POLITICA IN MATERIA DI PROTEZIONE DEI DATI PERSONALI</strong>
      </li>
      <p>
        <strong>
          <u>Titolare del trattamento di dati</u>
        </strong>
      </p>
      <p>
        Titolare del trattamento di dati &egrave; Hextech, generalit&agrave; suddette, rappresentata
        dai propri organi conformemente alle iscrizioni del Registro di Commercio (
        <a href="https://ti.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-358.260.235">
          link
        </a>
        ).
      </p>
      <p>
        Il Titolare pu&ograve; essere contattato utilizzando i recapiti specificati al <u>par. A</u>{' '}
        precedente.
      </p>
      <p>
        <strong>
          <u>Supervisore della protezione dei dati</u>
        </strong>
      </p>
      <p>
        Il Titolare, nell&rsquo;ambito della promozione del rapporto di fiducia con l&rsquo;utente
        in relazione al trattamento corretto dei suoi dati personali, ha nominato un supervisore
        della protezione dei dati, il cui compito &egrave; di fornire consulenza al Titolare sul
        regime applicabile ai dati, condurre verifiche regolari, rispondere alle richieste di
        informazioni e dare seguito all&rsquo;esercizio dei diritti degli utenti.
      </p>
      <p>
        Il Supervisore della protezione dei dati pu&ograve; essere contattato tramite posta
        ordinaria al seguente indirizzo: Supervisore della protezione dei dati c/o Hextech SA, Via
        Paradiso 24, 6924 Sorengo (CH).
      </p>
      <p>
        Qualsivoglia comunicazione relativa alla protezione dei dati personali pu&ograve; essere
        trasmessa anche al seguente indirizzo di posta elettronica:&nbsp;
        <a href="mailto:privacy@mangiami.ch">privacy@mangiami.ch</a>.
      </p>
      <p>
        <strong>
          <u>Regime giuridico applicabile al trattamento di dati</u>
        </strong>
      </p>
      <p>
        Il Titolare, in quanto persona giuridica di diritto svizzero con sede in Svizzera attiva,
        tratta i dati personali degli utenti conformemente alla Legge federale sulla protezione dei
        dati personali (&ldquo;
        <a href="https://www.admin.ch/opc/it/classified-compilation/19920153/index.html">LPD</a>
        &rdquo;, RS 235.1).&nbsp;
      </p>
      <p>
        Premesso che Hextech di principio non effettua trattamenti di dati personali che rientrano
        nel campo di applicazione del GDPR (cfr. <u>par. A</u> precedente), ove il Regolamento fosse
        eccezionalmente applicabile, il Titolare concede agli interessati le tutele previste dal
        GDPR medesimo (in particolare i diritti di cui agli artt. 12 &ndash; 23). Il testo del GDPR
        &egrave; consultabile attivando questo&nbsp;
        <a href="https://eur-lex.europa.eu/legal-content/IT/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ITA">
          link
        </a>
        .
      </p>
      <p>
        <strong>
          <u>Nozioni e categorie di dati personali</u>
        </strong>
      </p>
      <p>
        Per dati personali s&rsquo;intende qualsiasi informazione riguardante una persona fisica
        identificata o identificabile (&ldquo;Dati Personali&rdquo;). Sono considerati dati
        personali meritevoli di particolare protezione segnatamente le informazioni sensibili quali
        quelle riguardanti la sfera intima, le misure d&#39;assistenza sociale, l&rsquo;origine
        razziale ed etnica, le opinioni politiche, le convinzioni religiose o filosofiche,
        l&rsquo;iscrizione sindacale, i dati biometrici o relativi alla salute, lo stato psichico,
        mentale o fisico, come pure i dati relativi a condanne penali e ai reati o a connesse misure
        di sicurezza.
      </p>
      <p>
        Il Titolare non necessita, n&eacute; richiede il conferimento di dati personali meritevoli
        di particolare protezione.{' '}
        <u>
          Si raccomanda pertanto all&rsquo;utente di non trasmettere spontaneamente informazioni di
          tale natura tramite la Piattaforma (in particolare: e-mail, formulario di contatto, social
          media ecc.)
        </u>
        .
      </p>
      <p>
        <strong>
          <u>Finalit&agrave; e liceit&agrave; del trattamento</u>
        </strong>
      </p>
      <p>
        Il Titolare tratta i Dati Personali in relazione alle finalit&agrave; riassunte nella
        seguente tabella:
      </p>
      <table border="1" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>
                <strong>
                  <em>Finalit&agrave; del trattamento</em>
                </strong>
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                <strong>
                  <em>Base legale</em>
                </strong>
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                <strong>
                  <em>Periodo conservazione dati</em>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>Utilizzazione della Piattaforma (Sito, App, Social Media)</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Interesse privato preponderante</p>
              <p>| adempimento&nbsp;</p>
              <p>obblighi contrattuali</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>12 mesi; l&rsquo;informativa sui cookies e social plug-in &egrave; riservata</p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>Deposito candidatura lavorativa tramite posta o i canali informatici</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Interesse privato preponderante |</p>
              <p>obblighi precontrattuali e contrattuali&nbsp;</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                Fino ad attribuzione del posto di lavoro, salvo consenso rilasciato dal candidato
                (non assunto) alla conservazione per un periodo ulteriore; in caso di candidatura
                spontanea: cancellazione immediata salvo consenso rilasciato dal candidato (non
                assunto) alla conservazione per un periodo ulteriore; in caso di assunzione, la
                documentazione viene inserita nel dossier del dipendente
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>
                Attivit&agrave; di natura organizzativa, amministrativa, finanziaria e contabile e
                gestione dati clienti / utenti, a prescindere dalla natura dei dati trattati. In
                particolare, perseguono tali finalit&agrave; le attivit&agrave; organizzative
                interne.
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Interesse privato preponderante | legge</p>
              <p>&nbsp;</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                Generale: 10 anni (termine generale legale per la conservazione dei documenti con
                valenza contabile); altrimenti, distruzione o anonimizzazione non appena raggiunta
                la finalit&agrave; alla base del trattamento
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>Newsletter</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                <u>In caso di clienti esistenti</u>: Interesse privato preponderante |&nbsp;
              </p>
              <p>adempimento&nbsp;</p>
              <p>obblighi contrattuali</p>
              <p>
                <u>In caso di clienti prospettici o al beneficio del GDPR</u>: consenso
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Fino a cancellazione dell&rsquo;iscrizione</p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>Formulario online</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Interesse privato preponderante | adempimento&nbsp;</p>
              <p>obblighi contrattuali</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                Generale: 12 mesi; 10 anni qualora la comunicazione avesse valenza contabile,
                contrattuale o commerciale
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>Comunicazioni e-mail</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Interesse privato preponderante | adempimento&nbsp;</p>
              <p>obblighi contrattuali</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                Generale: 12 mesi; 10 anni qualora la comunicazione avesse valenza contabile,
                contrattuale o commerciale
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>App per dispositivi mobili:</p>
              <p>
                Iscrizione a servizio di notifica &ldquo;push&rdquo;; l&rsquo;utente sceglie il
                servizio e le segnalazioni che desidera ricevere
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Adempimento&nbsp;</p>
              <p>obblighi contrattuali</p>
              <p>&nbsp;</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>
                Generale: 12 mesi; 10 anni qualora la comunicazione avesse valenza contabile,
                contrattuale o commerciale
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>App per dispositivi mobili:</p>
              <p>geolocalizzazione dell&rsquo;utente</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Adempimento&nbsp;</p>
              <p>
                obblighi contrattuali ove strettamente necessario per l&rsquo;erogazione del
                servizio | Altri servizi: consenso dell&rsquo;utente (fino a revoca)
              </p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>12 mesi</p>
            </td>
          </tr>
          <tr>
            <td valign="top" width="33.333333333333336%">
              <p>App per dispositivi mobili e Newsletter:</p>
              <p>Profilazione ai fini di marketing e pubblicit&agrave; comportamentale</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>Consenso dell&rsquo;utente (fino a revoca)</p>
            </td>
            <td valign="top" width="33.333333333333336%">
              <p>12 mesi</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Il&nbsp;Titolare raccoglie e tratta{' '}
        <u>
          i Dati Personali necessari per rendere possibile, rispettivamente ottimizzare, l&rsquo;uso
          della Piattaforma
        </u>
        . Tali dati comprendono le informazioni concernenti l&rsquo;utilizzo del Sito e
        dell&rsquo;App, in particolare la corrispondenza tra le parti, l&rsquo;indirizzo e-mail, i
        dati di log-in (nome e cognome), l&rsquo;indirizzo IP del dispositivo dell&rsquo;utente, la
        sua localizzazione&nbsp;quando utilizza l&rsquo;App oppure se l&rsquo;App rimane aperta,
        l&rsquo;eventuale identificativo univoco del dispositivo mobile, le caratteristiche del
        browser (tipologia, lingua, plug-in installati ecc.), le pagine visitate, la durata della
        permanenza sul Sito, i collegamenti attivati, i cookie, le scelte d&rsquo;acquisto. Tali
        dati vengono trattati in maniera automatizzata per fornire offerte mirate ai singoli utenti,
        consentire la navigazione sul Sito o l&rsquo;uso dell&rsquo;App, valutare
        l&rsquo;introduzione di nuove funzionalit&agrave;, migliorare la qualit&agrave; dei servizi
        offerti, misurare l&rsquo;uso del Sito e dell&rsquo;App e ottimizzarne la fruibilit&agrave;.
      </p>
      <p>
        La Piattaforma tratta i <u>Dati Personali trasmessi volontariamente dall&rsquo;utente</u>,
        in particolare mediante log-in, il formulario online o la posta elettronica, ai fini di
        comunicazione, di adempimento contrattuale oppure di messa a disposizione delle informazioni
        richieste.
      </p>
      <p>
        <u>
          La Piattaforma profila l&rsquo;utente ai fini del marketing diretto. Essa elabora e
          trasmette contenuti o messaggi pubblicitari concepiti in base al comportamento
          dell&rsquo;utente online e/o attraverso l&rsquo;App oppure l&rsquo;uso della Newsletter.
          La Piattaforma non monitora l&rsquo;utilizzo di risorse online esterne al dominio
          mangiami.ch (salvo ricevere l&rsquo;indicazione della risorsa online dalla quale
          l&rsquo;utente giunge sul Sito oppure indicazioni sui link della Newsletter attivati)
        </u>
        .<u>&nbsp;</u>
      </p>
      <p>
        <u>La Piattaforma non vende, affitta, commercia e/o presta Dati Personali a terzi.</u>
      </p>
      <p>I dati raccolti tramite la Piattaforma vengono ripresi nel gestionale aziendale.</p>
      <p>
        Si raccomanda di non trasmettere per e-mail informazioni e/o documenti contenenti
        informazioni personali e/o confidenziali, trattandosi di un mezzo di comunicazione insicuro
        e che non garantisce la tutela della riservatezza. Hextech &egrave; disponibile a fornire,
        su richiesta dell&rsquo;utente, canali sicuri di comunicazione elettronica per trasmettere
        dati sensibili.
      </p>
      <p>
        <strong>
          <u>Obbligo di conferimento dati; alternative alla comunicazione digitale</u>
        </strong>
      </p>
      <p>
        A parte quanto specificato per i dati necessari per rendere possibile, rispettivamente
        ottimizzare, l&rsquo;uso della Piattaforma, l&#39;utente &egrave; libero di fornire o meno i
        Dati Personali.
      </p>
      <p>
        Il mancato conferimento dei dati necessari, ad esempio il blocco generalizzato dei cookies
        oppure l&rsquo;omessa comunicazione di un indirizzo e-mail nell&rsquo;ambito
        dell&rsquo;iscrizione alla Newsletter, comporter&agrave; l&#39;impossibilit&agrave; di
        ottenere quanto richiesto o di usufruire pienamente dei servizi digitali offerti dal
        Titolare.
      </p>
      <p>
        In generale, il Titolare sottolinea che l&rsquo;interessato ha la facolt&agrave; di
        utilizzare i canali ordinari di comunicazione, quali il telefono e la posta ordinaria,
        qualora non intendesse usufruire dei canali elettronici. La Newsletter, per contro, per
        motivi di economicit&agrave;, viene fornita solo elettronicamente.
      </p>
      <p>
        <strong>
          <u>Trasferimento dati verso un paese terzo e/o un&rsquo;organizzazione internazionale</u>
        </strong>
      </p>
      <p>
        Ove strettamente necessario tenuto conto della finalit&agrave; del trattamento, i Dati
        Personali vengono trasferiti all&rsquo;estero (rispetto alla Svizzera), limitatamente
        tuttavia all&rsquo;Unione Europea o a Paesi che garantiscono una tutela adeguata dei dati
        personali (rispetto al diritto svizzero) come da Elenco stabilito dall&rsquo;Autorit&agrave;
        federale (
        <a href="https://www.edoeb.admin.ch/dam/edoeb/fr/dokumente/2018/staatenliste.pdf.download.pdf/20181213_Staatenliste_f.pdf">
          link
        </a>
        ), rispettivamente dall&rsquo;Autorit&agrave; europea competente in relazione ai trattamenti
        di dati (eccezionalmente) assoggettati al GDPR. L&rsquo;interessato ha diritto di ottenere
        una copia di tali dati, indicando il motivo e comprovando la propria legittimazione.
      </p>
      <p>
        In caso di trasferimento verso paesi extra-europei, in particolare gli Stati Uniti, il cui
        il livello di protezione dei dati non &egrave; stato ritenuto generalmente adeguato dalle
        Autorit&agrave; UE e CH, i dati personali potranno essere trasferiti solo a persone fisiche,
        enti e societ&agrave; che abbiano aderito a specifici accordi e/o strumenti internazionali
        aventi per oggetto la tutela dei dati personali (ad esempio: Swiss / EU &ndash; US Privacy
        Shield). L&rsquo;interessato potr&agrave; ottenere informazioni circa le misure di garanzia
        adottate per il trasferimento dei Dati Personali rivolgendo una richiesta al Titolare del
        trattamento per e-mail, indicando il motivo e comprovando la propria legittimazione.
      </p>
      <p>
        <strong>
          <u>Periodo di conservazione dei dati personali</u>
        </strong>
      </p>
      <p>
        Il Sito mantiene i Dati Personali fintanto che la loro conservazione sia necessaria in
        considerazione delle finalit&agrave; per le quali i dati sono stati raccolti,
        rispettivamente nella misura in cui sussista un obbligo legale di conservazione (di norma 10
        anni) (vedi tabella sopra). Decaduta la finalit&agrave; della raccolta dei Dati Personali,
        rispettivamente scaduto l&rsquo;obbligo di conservazione stabilito dalla legge, il Titolare
        provvede alla cancellazione definitiva e sicura dei dati o, in alternativa, alla loro
        anonimizzazione.
      </p>
      <p>
        L&rsquo;accesso alla politica di dettaglio in materia di conservazione dei dati personali
        pu&ograve; essere richiesto al Titolare del trattamento per e-mail, indicando il motivo e
        comprovando la propria legittimazione.
      </p>
      <p>
        <strong>
          <u>Formulario di contatto</u>
        </strong>
      </p>
      <p>
        Il Sito mette a disposizione un formulario di contatto online, basato su un plug-in di terze
        parti. Il plug-in &egrave; impostato per inviare automaticamente i dati inseriti nel
        formulario tramite e-mail al Titolare, senza conservarne copia.
      </p>
      <p>
        Il Titolare non ha alcun potere d&rsquo;intervento sullo sviluppatore e/o sul servizio, per
        cui non &egrave; in grado di rilasciare informazioni e/o garanzie sulla qualit&agrave; e in
        tema di trattamento dei dati personali. L&rsquo;utente pu&ograve; assumere tutte le
        informazioni rilevanti visitando il sito dello sviluppatore.&nbsp;
      </p>
      <p>
        Qualora non intendesse accettare il servizio di terzi, l&rsquo;utente deve rinunciare al
        formulario online e inviare una richiesta di contatto facendo capo ai vari strumenti /
        recapiti specificati al <u>par. A</u> precedente.
      </p>
      <p>
        <strong>
          <u>Newsletter</u>
        </strong>
      </p>
      <p>
        Il Titolare pu&ograve; offrire un servizio gratuito di aggiornamento sulle proprie
        attivit&agrave; e di proposte personalizzate nel campo del cibo e della ristorazione
        mediante Newsletter.
      </p>
      <p>
        La Newsletter viene trasmessa esclusivamente a coloro che si sono registrati a tal fine
        fornendo il proprio indirizzo e-mail. La cancellazione immediata dalla lista dei destinatari
        &egrave; possibile in ogni momento e con effetto immediato attivando il collegamento &ldquo;
        <em>unsubscribe</em>&rdquo; posto in calce a ogni e-mail.
      </p>
      <p>
        La mancata iscrizione alla Newsletter oppure la cancellazione dalla lista dei destinatari
        non pregiudicano, n&eacute; riducono in alcun modo la fruibilit&agrave; della Piattaforma.
      </p>
      <p>
        <u>
          Il Titolare monitora il comportamento e profila i destinatari della Newsletter, rilevando
          l&rsquo;apertura o meno dell&rsquo;e-mail e l&rsquo;attivazione dei collegamenti contenuti
          al suo interno.
        </u>{' '}
        Il Titolare non trasferisce gli indirizzi e-mail degli utenti a terzi, fatta eccezione per
        il fornitore del servizio di Newsletter, il quale riceve gli indirizzi e-mail dei
        destinatari funzionalmente alla gestione della Newsletter. Quest&rsquo;ultimo deve essere
        stabilito in Svizzera, nell&rsquo;UE oppure negli USA (in quest&rsquo;ultimo caso solo se ha
        aderito allo US &ndash; CH/UE Privacy Shield).
      </p>
      <p>
        <strong>
          <u>Social media</u>
        </strong>
      </p>
      <p>Il Titolare detiene e gestisce pagine e profili sui principali social media.</p>
      <p>
        La Piattaforma consente inoltre all&rsquo;utente di condividere contenuti attraverso i
        propri social media tramite l&rsquo;attivazione dei cosiddetti widget (cfr. <u>par. D</u>{' '}
        seguente).
      </p>
      <p>
        Si attira l&rsquo;attenzione dell&rsquo;utente sul fatto che i social media raccolgono,
        trattano e comunicano a terzi, inclusi i terzi stabiliti in Stati che non proteggono
        adeguatamente i dati personali (rispetto al diritto svizzero), in maniera estesa, dati
        personali relativi all&rsquo;utente. Utilizzando tali risorse, sulle quali il Titolare non
        detiene alcun potere di controllo, l&rsquo;utente prende atto e accetta che si applicano le
        disposizioni sulla privacy e sulla protezione dei dati emanate dai singoli social media e
        valide al momento dell&rsquo;accesso (in particolare:&nbsp;
        <a href="https://it-it.facebook.com/policy.php">Facebook</a>,&nbsp;
        <a href="https://help.instagram.com/155833707900388/?helpref=hc_fnav&bc%5b0%5d=Centro%20assistenza%20di%20Instagram&bc%5b1%5d=Privacy%20e%20Centro%20per%20la%20sicurezza">
          Instagram
        </a>
        ,&nbsp;
        <a href="https://help.twitter.com/it/rules-and-policies/personal-information">Twitter</a>
        ,&nbsp;<a href="https://www.linkedin.com/legal/privacy-policy?_l=it_IT">LinkedIN</a>).
      </p>
      <p>
        L&rsquo;utente s&rsquo;impegna ad astenersi dall&rsquo;uso dei social media qualora non
        fosse d&rsquo;accordo con le regole stabilite dai relativi provider.&nbsp;
      </p>
      <p>
        <strong>
          <u>App per dispositivi mobili</u>
        </strong>
      </p>
      <p>
        Con lo scaricamento dell&rsquo;App sul proprio dispositivo, l&rsquo;utente conferisce le
        seguenti autorizzazioni ad acquisire informazioni personali attraverso il dispositivo mobile
        in relazione al funzionamento e all&rsquo;uso del dispositivo medesimo:
      </p>
      <ul>
        <li>Geolocalizzazione, se l&rsquo;utente ha dato il consenso</li>
        <li>Notifiche push, se l&rsquo;utente ha dato il consenso</li>
      </ul>
      <p>
        <strong>
          <u>Esclusione di responsabilit&agrave;</u>
        </strong>
      </p>
      <p>
        Le informazioni disponibili mediante l&rsquo;App potrebbero non essere corrette oppure
        aggiornate, data l&rsquo;evoluzione rapida delle circostanze. L&rsquo;utente non deve fare
        affidamento su tali informazioni, ritenuto che AIL non rilascia alcuna garanzia completezza
        e/o correttezza. Ogni responsabilit&agrave; di AIL &egrave; pertanto esclusa.
      </p>
      <p>
        <strong>
          <u>Dati relativi a minori</u>
        </strong>
      </p>
      <p>
        Il trattamento giustificato dal consenso dell&rsquo;interessato &egrave; lecito ove il
        minore che abbia manifestato il consenso abbia almeno 16 anni. Ove il minore abbia
        un&rsquo;et&agrave; inferiore ai 16 anni, il trattamento dei dati personali &egrave; lecito
        soltanto e nella misura in cui il consenso sia prestato o autorizzato dal rappresentante
        legale. Il Titolare si adoperer&agrave; in ogni modo ragionevole e in considerazione delle
        tecnologie disponibili per verificare che il consenso prestato dal rappresentante legale sia
        effettivo. Tuttavia, non sar&agrave; in alcun modo responsabile di eventuali dichiarazioni
        mendaci che dovessero essere fornite dal minore e, in ogni caso, qualora accertasse la
        falsit&agrave; della dichiarazione, provveder&agrave; alla cancellazione immediata di ogni
        dato personale e di qualsivoglia materiale acquisito. Il Titolare agevoler&agrave; le
        richieste inerenti ai dati personali dei minori provenienti dal rappresentante legale.&nbsp;
      </p>
      <p>
        <strong>
          <u>
            Responsabili del trattamento dei dati, destinatari o categorie di destinatari, accesso
            ai dati
          </u>
        </strong>
      </p>
      <p>
        I Dati Personali forniti dall&rsquo;utente potranno essere comunicati a destinatari che
        tratteranno i dati in qualit&agrave; di Responsabili del trattamento e/o in qualit&agrave;
        di persone fisiche che agiscono sotto l&rsquo;autorit&agrave; del Titolare o del
        Responsabile. Ove operino in piena autonomia, i soggetti assumono la posizione di distinti
        Titolari del trattamento.
      </p>
      <p>
        Riservate le trasmissioni di dati previste dalla legge, i dati potranno essere comunicati a
        destinatari appartenenti alle seguenti categorie:&nbsp;
      </p>
      <ul>
        <li>
          soggetti che forniscono servizi per la gestione dei sistemi informativi e di
          telecomunicazione usati dal Titolare del trattamento per la messa a disposizione della
          Piattaforma e per l&rsquo;organizzazione, la programmazione, la realizzazione e
          l&rsquo;esecuzione delle attivit&agrave; legate alla Piattaforma;
        </li>
        <li>
          societ&agrave; e liberi professionisti che prestano servizi al Titolare del trattamento,
          ad esempio nel campo legale, contabile, amministrativo e fiscale.
        </li>
      </ul>
      <p>
        Nell&rsquo;ambito della gestione della Piattaforma (in particolare: e-mail, marketing,
        back-up, web-design, grafica, manutenzione, traduzione, hosting e accesso a Internet),
        Hextech si avvale di fornitori esterni di beni e/o di servizi stabiliti e attivi in
        Svizzera, nell&rsquo;UE&nbsp;oppure negli USA (in quest&rsquo;ultimo caso solo se ha aderito
        allo US &ndash; CH/UE Privacy Shield).
      </p>
      <p>
        I fornitori esterni hanno accesso ai dati solo nella misura strettamente necessaria per il
        corretto ed efficiente svolgimento dei propri compiti, previa assunzione, mediante
        convenzione, di un obbligo di riservatezza e non uso in relazione ai Dati Personali.
      </p>
      <p>
        L&rsquo;elenco completo ed aggiornato dei Responsabili del trattamento &egrave; disponibile
        in visione presso la sede sociale agli interessati che abbiano indicato per iscritto il
        motivo della richiesta e comprovato la propria legittimazione. Per ragioni di sicurezza
        informatica, talune informazioni potrebbero risultare anonimizzate o mascherate.
      </p>
      <p>
        <strong>
          <u>Comunicazioni via e-mail, rischi</u>
        </strong>
      </p>
      <p>
        Si rende attento l&rsquo;utente che (i) l&rsquo;uso dell&rsquo;e-mail non assicura la
        riservatezza e l&rsquo;integrit&agrave; dei dati in transito, (ii) numerosi fornitori di
        servizi di posta elettronica sono localizzati o detengono i propri dati in Paesi che non
        garantiscono una protezione adeguata dei dati personali (ad esempio gli USA, vedi lista
        ufficiale aggiornata scaricabile&nbsp;
        <a href="https://www.edoeb.admin.ch/dam/edoeb/it/dokumente/2017/04/staatenliste.pdf.download.pdf/elenco_dei_paesiinfrancese.pdf">
          qui
        </a>
        ), (iii) l&rsquo;utilizzazione di un tale servizio e-mail determina il trasferimento e la
        conservazione di dati in un Paese che non garantisce una protezione adeguata di tali dati.
      </p>
      <p>
        L&rsquo;utente autorizza il Titolare (inclusi organi, ausiliari, mandatari e delegatari) a
        trasmettere mediante posta elettronica ordinaria (non certificata e/o crittografata)
        documenti e/o informazioni, incluse quelli contenenti dati personali e/o confidenziali,
        utilizzando l&rsquo;indirizzo e-mail fornito dall&rsquo;utente in risposta alle richieste
        dell&rsquo;utente ricevute via posta, telefono o e-mail. L&rsquo;utente, in piena coscienza
        dei rischi sopra menzionati, esonera il Titolare da ogni responsabilit&agrave; in caso di
        accesso non autorizzato di terzi ai documenti e/o alle informazioni personali e/o
        confidenziali trasmessi o ricevuti via e-mail dal Titolare e/o dai suoi organi e ausiliari.
      </p>
      <p>
        <strong>
          <u>Collegamenti verso risorse di terzi</u>
        </strong>
      </p>
      <p>
        La Piattaforma contiene collegamenti verso siti, servizi, prodotti e altre risorse di
        Internet riferibili a terze parti (inclusi i social media e i plug-in). Il Titolare non
        &egrave; in alcun modo responsabile per i contenuti, la sicurezza e la fruibilit&agrave; di
        tali risorse; in particolare, il Titolare non verifica la politica, n&eacute; rilascia
        garanzie in tema di tutela della privacy e dei dati personali da parte di detti terzi.
      </p>
      <p>
        <strong>
          <u>Sicurezza</u>
        </strong>
      </p>
      <p>
        La Piattaforma implementa le misure di sicurezza ragionevolmente imposte dalle circostanze e
        proporzionate ai rischi contro l&rsquo;accesso non autorizzato, l&rsquo;utilizzo, la
        trasmissione, l&rsquo;alterazione, la perdita, l&rsquo;indisponibilit&agrave; o la
        distruzione dei Dati Personali. Tali misure comprendono provvedimenti di natura tecnica e
        organizzativa. Tuttavia, considerata la natura di Internet quale &ldquo;rete aperta&rdquo;,
        il Titolare non pu&ograve; garantire, n&eacute; garantisce, che i dati non siano
        intercettati o acquisiti da terzi non autorizzati.
      </p>
      <p>
        <strong>
          <u>Diritti dell&rsquo;utente</u>
        </strong>
      </p>
      <p>
        Entro i limiti&nbsp;stabiliti dalla LPD, l&rsquo;interessato pu&ograve;, in particolare:
      </p>
      <ul type="disc">
        <li>ottenere la rettifica di dati personali inesatti (art. 5 cpv. 2 LPD);</li>
        <li>
          chiedere gratuitamente e con risposta scritta se vengono trattati dati che lo concernono
          (art. 8 cpv. 1 LPD);
        </li>
        <li>
          far interrompere o revocare il consenso al trattamento di dati personali (art. 12 cpv. 2
          lett. b) LPD);
        </li>
        <li>
          far interrompere un&rsquo;elaborazione illecita di dati personali (art. 12 cpv. 2 lett. a)
          LPD);
        </li>
        <li>
          impedire, in assenza di giustificazione, la comunicazione a terzi di dati personali degni
          di particolare protezione o profili della personalit&agrave; (art. 12 cpv. 2 lett. c)
          LPD);
        </li>
        <li>
          chiedere che l&#39;elaborazione dei dati venga bloccata, che se ne impedisca la
          comunicazione a terzi o che i dati personali siano rettificati o distrutti (art. 15 cpv. 1
          LPD);
        </li>
        <li>
          se non pu&ograve; essere dimostrata n&eacute; l&#39;esattezza n&eacute; l&#39;inesattezza
          dei dati personali, chiedere che si aggiunga ai dati una menzione che ne rilevi il
          carattere contestato (art. 15 cpv. 1 LPD);
        </li>
        <li>
          chiedere che la rettifica, la distruzione, il blocco, segnatamente quello della
          comunicazione a terzi, nonch&eacute; la menzione del carattere contestato o la sentenza
          siano comunicati a terzi o pubblicati (art. 15 cpv. 3 LPD);
        </li>
        <li>
          far distruggere i dati personali raccolti, conservati o utilizzati in modo illecito;
        </li>
        <li>far costatare l&rsquo;illegalit&agrave; di un&rsquo;elaborazione di dati personali.</li>
      </ul>
      <p>
        Se il trattamento di dati rientra nel campo di applicazione territoriale di cui
        all&rsquo;art. 3 GDPR, l&rsquo;interessato pu&ograve; far valere i diritti come espressi
        dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 GDPR, rivolgendosi al Titolare oppure al
        responsabile del trattamento. Il testo del GDPR &egrave; consultabile attivando questo&nbsp;
        <a href="https://eur-lex.europa.eu/legal-content/IT/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ITA">
          link
        </a>
        . L&rsquo;utente ha il diritto, in qualunque momento, nei limiti e alle condizioni stabilite
        dal GDPR, di chiedere al Titolare del trattamento l&rsquo;accesso ai suoi dati personali, la
        rettifica, la cancellazione degli stessi, la limitazione del trattamento che lo riguardano o
        di opporsi al loro trattamento nonch&eacute; di esercitare il diritto alla
        portabilit&agrave; di tali dati. Qualora il trattamento sia basato sull&rsquo;articolo 6,
        paragrafo 1, lettera a) oppure sull&rsquo;articolo 9, paragrafo 2, lettera a) GDPR,
        l&rsquo;utente ha il diritto di revocare il consenso in qualsiasi momento senza pregiudicare
        la liceit&agrave; del trattamento basata sul consenso prestato prima della revoca.
        L&rsquo;utente ha il diritto di proporre reclamo all&rsquo;Autorit&agrave; di controllo
        competente. Nel caso di richiesta di portabilit&agrave; del dato, il Titolare del
        trattamento fornisce all&rsquo;utente, in un formato strutturato di uso comune e leggibile
        da dispositivo automatico, i dati personali che lo riguardano, fatto salvo i commi 3 e 4
        dell&rsquo;art. 20 GDPR.
      </p>
      <li>
        <strong>COOKIE E SOCIAL MEDIA PLUG-IN</strong>
      </li>
      <p>
        <strong>
          <u>Introduzione</u>
        </strong>
      </p>
      <p>
        Il presente capitolo descrive la politica della Piattaforma in relazione al trattamento dei
        dati personali degli utenti in relazione ai cosiddetti &ldquo;cookie&rdquo; e
        &ldquo;widget&rdquo;.
      </p>
      <p>
        <strong>
          <u>Nozioni tecniche</u>
        </strong>
      </p>
      <p>
        <u>Cosa sono i cookie?</u>
      </p>
      <p>
        I cookie sono file di testo depositati nel browser dell&rsquo;utente da siti / App o server
        durante la navigazione sul web. Grazie ai cookie, tali siti o server sono in grado di
        riconoscere il browser durante la navigazione e successivamente. I cookie contribuiscono a
        migliorare l&rsquo;esperienza dell&rsquo;utente online, ad esempio conservando nel tempo le
        preferenze espresse dall&rsquo;utente oppure evitando all&rsquo;utente di dover eseguire il
        log-in ad ogni cambio di pagina. I cookie possono essere utilizzati anche per monitorare il
        comportamento dell&rsquo;utente online, con conseguente impatto sulla privacy
        dell&rsquo;utente.
      </p>
      <p>
        <u>Cosa sono i social media plug-in e i widget?</u>
      </p>
      <p>
        I social plug-in consistono in moduli software opzionali che collegano i siti web ai social
        media, onde consentire agli utenti di interagire agevolmente con i contenuti dei siti (ad
        esempio: &ldquo;Like&rdquo; o condivisione attraverso Facebook di un contenuto interessante
        del Sito). I plug-in social comprendono i cosiddetti &ldquo;widget&rdquo;, elementi di
        comando grafici che vengono inseriti nelle sezioni corrispondenti del sito web, onde
        consentire all&rsquo;utente di accedere alle funzionalit&agrave; del plug-in. Cliccando sul
        widget, l&rsquo;utente promuove quest&rsquo;ultimo sul corrispondente social media con un
        semplice click.&nbsp;Se l&rsquo;Utente attiva il plug-in, il suo browser effettua un
        collegamento diretto ai server del fornitore del plug-in (ad esempio Facebook). Il contenuto
        del plug-in viene trasmesso direttamente dal rispettivo fornitore al browser
        dell&rsquo;Utente e inserito nella pagina. Con l&rsquo;utilizzo dei social plug-in,
        determinate informazioni personali vengono trasmesse al fornitore del plug-in e da questo
        trattate.&nbsp;Anche se l&rsquo;utente non &egrave; un registrato al social media, esiste la
        possibilit&agrave; che questi raccolga l&rsquo;indirizzo IP tramite il social media plug-in.
        Se l&rsquo;utente &egrave; registrato, il social media pu&ograve; collegare la visita delle
        pagine web al profilo personale nel social media. Se l&rsquo;utente interagisce con un
        plug-in, la relativa informazione sar&agrave; trasmessa direttamente ai server del fornitore
        del plug-in. Le informazioni vengono inoltre pubblicate sul social media e pertanto mostrate
        ai contatti dell&rsquo;utente oppure rese pubbliche (in caso di profilo pubblico).
      </p>
      <p>
        <u>Le tipologie di cookie</u>
      </p>
      <p>I cookie possono essere suddivisi in varie tipologie.&nbsp;</p>
      <p>
        Considerando <u>il soggetto che deposita il cookie</u> sul terminale dell&rsquo;utente, se
        esso coincide con il titolare del sito visitato, il cookie &egrave; detto di &ldquo;prima
        parte&rdquo;, mentre se si tratta di un sito/server terzo, il cookie &egrave; detto di
        &ldquo;terza parte&rdquo;.
      </p>
      <p>
        Considerando la <u>durata del cookie</u>, i cookie &ldquo;di sessione&rdquo; vengono
        depositati in funzione dell&rsquo;accesso al sito e vengono pertanto eliminati alla chiusura
        del browser. I cookie &ldquo;persistenti&rdquo; restano invece memorizzati nel dispositivo
        dopo la chiusura del browser (e fino alla scadenza prevista dal cookie).
      </p>
      <p>
        Considerando le <u>finalit&agrave; del cookie</u>, occorre distinguere i cookie
        &ldquo;tecnici&rdquo; dai cookie &ldquo;di profilazione&rdquo;. I cookie tecnici rendono
        possibile la navigazione web, rispettivamente l&rsquo;erogazione del servizio richiesto
        dall&rsquo;utente. Essi non vengono utilizzati per altri scopi e in genere sono gestiti dal
        titolare del sito visitato. I cookie &ldquo;analytics&rdquo; o &ldquo;statistici&rdquo; sono
        assimilati ai cookie tecnici laddove utilizzati direttamente dal titolare del sito per
        raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi
        interagiscono con il sito. I cookie di profilazione sono generalmente cookie di terza parte
        utilizzati per allestire un profilo dell&rsquo;utente basato sul suo comportamento online e
        sulle sue abitudini al fine di sottoporgli messaggi pubblicitari personalizzati.
      </p>
      <p>
        <strong>
          <u>Affermazioni del Titolare in materia di cookie</u>
        </strong>
      </p>
      <p>
        <u>In generale</u>
      </p>
      <p>
        La Piattaforma implementa cookie tecnici o analitici di sessione e cookie tecnici o
        analitici persistenti, in particolare allo scopo di personalizzare la configurazione della
        Piattaforma, analizzare l&rsquo;uso della Piattaforma da parte degli utenti (anche in
        maniera personalizzata), mantenere attiva la navigazione, analizzare il flusso di traffico,
        nonch&eacute; ai fini dell&rsquo;amministrazione del sistema.
      </p>
      <p>
        <u>Elenco dei cookies attivi, finalit&agrave; e durata</u>
      </p>
      <p>La Piattaforma implementa i seguenti cookie:</p>
      <p>&nbsp;</p>
      <li>
        <em>Cookie analitici di terza parte rispettosi della privacy</em>
      </li>
      <li>Fornitore: Google</li>
      <li>Denominazione: Google Analytics</li>
      <li>Tipologia: Raccolta di informazioni in forma aggregata</li>
      <li>Finalit&agrave;:</li>
      <li>Mascheramento IP: SI</li>
      <li>Incrociamento con altri dati da parte del fornitore: NO</li>
      <li>Necessit&agrave; ai fini dell&rsquo;uso del Sito: NO</li>
      <li>
        Privacy policy del fornitore:&nbsp;
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </li>
      <p>&nbsp;</p>
      <li>
        <em>Cookie di profilazione di terza parte</em>
      </li>
      <li>Fornitore: Facebook</li>
      <li>Denominazione: Facebook Pixel &amp; Analytics</li>
      <li>Tipologia: Raccolta di informazioni in forma aggregata</li>
      <li>Necessit&agrave; ai fini dell&rsquo;Uso del Sito: NO</li>
      <li>
        Privacy policy del fornitore:&nbsp;
        <a href="https://www.facebook.com/policy">https://www.facebook.com/policy</a>
      </li>
      <p>&nbsp;</p>
      <p>
        <strong>
          <u>Affermazioni del Titolare in materia di social media plug-in</u>
        </strong>
      </p>
      <p>
        <u>In generale</u>
      </p>
      <p>
        I social plug-in, ove presenti, sono impostati nella procedura a &ldquo;due click&rdquo;,
        per cui il plug-in viene attivato solo se l&rsquo;utente clicca sul relativo widget.
      </p>
      <p>
        <u>Elenco dei social media plug-in attivi, fornitori, protezione dei dati</u>
      </p>
      <ul>
        <li>
          Facebook; politica protezione dei dati e privacy (
          <a href="https://www.facebook.com/policy">link</a>)
        </li>
        <li>
          Instagram:&nbsp;politica protezione dei dati e privacy (
          <a href="https://help.instagram.com/402411646841720">link</a>)
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>
          <u>Possibilit&agrave; di disattivazione o cancellazione, conseguenze tecniche</u>
        </strong>
      </p>
      <p>
        <u>Cookie</u>
      </p>
      <p>
        L&rsquo;utente ha la possibilit&agrave; di impostare il proprio browser affinch&eacute; lo
        informi della ricezione di un cookie oppure blocchi i cookie (in maniera generalizzata
        oppure per tipologia di cookie oppure ancora per sito di provenienza). Il blocco
        generalizzato dei cookie, dato che lo stesso si applica anche ai cookie tecnici, pu&ograve;
        determinare gravi limitazioni nell&rsquo;uso della Piattaforma (in particolare del Sito).
        L&rsquo;utente ha la possibilit&agrave; di cancellare i cookie dalla memoria del browser,
        come pure di impostare il browser affinch&eacute; cancelli automaticamente i cookie alla
        chiusura del programma (scelta consigliata).
      </p>
      <p>
        Per default i browser accettano automaticamente i cookie. Le istruzioni per disattivare o
        cancellare i cookie sono reperibili sul sito dello sviluppatore del proprio browser;
        rinviamo alle seguenti istruzioni per i browser pi&ugrave; comuni:&nbsp;
        <a
          href="https://support.microsoft.com/it-it/help/278835/how-to-delete-cookie-files-in-internet-explorer"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Internet Explorer
        </a>{' '}
        e&nbsp;
        <a
          href="https://privacy.microsoft.com/it-it/windows-10-microsoft-edge-and-privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Edge
        </a>
        ;&nbsp;
        <a
          href="https://support.google.com/accounts/answer/61416?hl=it"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </a>
        ;&nbsp;
        <a
          href="https://support.apple.com/it-ch/guide/safari/sfri11471/mac"
          target="_blank"
          rel="noopener noreferrer"
        >
          Apple Safari
        </a>
        ;&nbsp;
        <a
          href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozilla Firefox
        </a>{' '}
        e&nbsp;<a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a>.
      </p>
      <p>Vi sono ulteriori metodi per ridurre il rischio di tracciamento online, fra i quali:</p>
      <ul type="disc">
        <li>
          attivare l&rsquo;opzione&nbsp;
          <a href="http://donottrack.us/" target="_blank" rel="noopener noreferrer">
            DoNotTrack
          </a>{' '}
          sul browser (se disponibile);
        </li>
        <li>
          utilizzare la funzionalit&agrave; di navigazione &ldquo;privata&rdquo; o
          &ldquo;anonima&rdquo; del browser (se disponibile), la quale impedisce che i cookie
          vengano mantenuti sul dispositivo dopo la navigazione;
        </li>
        <li>
          installare sul browser privacy plug-ins quali, ad esempio,&nbsp;
          <a href="https://www.eff.org/privacybadger" target="_blank" rel="noopener noreferrer">
            Privacy Badger
          </a>{' '}
          o&nbsp;
          <a href="https://www.ghostery.com/" target="_blank" rel="noopener noreferrer">
            Ghostery
          </a>
          ;
        </li>
        <li>
          esercitare il diritto di essere esclusi da specifici schemi di pubblicit&agrave;
          comportamentale (ad esempio:&nbsp;
          <a href="http://optout.aboutads.info/#!/" target="_blank" rel="noopener noreferrer">
            DAA Consumer Opt-Out Page
          </a>
          ;&nbsp;
          <a
            href="http://www.networkadvertising.org/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            NAI Consumer Opt-Out Page
          </a>
          ).
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <u>Social media plug-in</u>
      </p>
      <p>
        Se l&rsquo;utente intende impedire che i social media assegnino i dati raccolti dalla
        Piattaforma al profilo personale nel rispettivo social media, prima di visitare le nostre
        pagine web occorre effettuare il log-out dal rispettivo social media e cancellare tutti i
        dati di navigazione dal browser. L&rsquo;utente pu&ograve; impedire del tutto il caricamento
        dei plug-in anche con add-ons specializzati per il browser (soluzione raccomandata), quali,
        ad esempio,&nbsp;<a href="http://noscript.net/">NoScript</a> o&nbsp;
        <a href="https://www.ghostery.com/" target="_blank" rel="noopener noreferrer">
          Ghostery
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <li>
        <strong>DIRITTO APPLICABILE E FORO COMPETENTE</strong>
      </li>
      <p>
        <strong>
          Il rapporto giuridico tra l&rsquo;utente e Hextech SA, Sorengo con riferimento
          all&rsquo;accesso e all&rsquo;uso del Sito (e delle risorse collegate) &egrave; regolato
          dal <u>diritto materiale svizzero</u>, escluse le norme di diritto internazionale privato.
        </strong>
      </p>
      <p>
        <strong>
          Le parti scelgono il Giudice ordinario presso la sede di Hextech SA, Sorengo, quale{' '}
          <u>Tribunale esclusivamente competente</u> in caso di controversia scaturente o
          semplicemente connessa con l&rsquo;utilizzo del Sito (e delle risorse correlate),
          riservate eventuali norme di diritto imperativo che impongano un foro differente. Hextech
          SA, Sorengo, si riserva il diritto di adire il Giudice competente presso la sede, la
          succursale o il domicilio dell&rsquo;utente.
        </strong>
      </p>
      <p>
        <u>&nbsp;</u>
      </p>
      <p>
        <u>Data di messa in vigore</u>: 09/07/2019
      </p>
    </div>
  </Layout>
);

export default PrivacyPolicy;
